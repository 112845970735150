<template>
  <div class="pa-3 mt-3 fill-height overflow-y-auto">
    <h2 class="primary--text font-weight-regular d-none d-sm-flex">
      <v-icon class="primary--text mr-2">mdi-alert-outline</v-icon>
      การทำนายความเสียหาย
    </h2>
    <v-card
      class="primary-border mt-4"
      :class="$vuetify.breakpoint.smAndUp ? '' : 'mt-12'"
      outlined
    >
      <v-card-text class="ma-0 my__v-card-text pa-4">
        <div
          class="d-flex"
          :class="$vuetify.breakpoint.smAndUp ? 'justify-end' : ''"
        >
          <div class="hidden-sm-and-up primary--text font-weight-bold">
            <v-icon class="primary--text">mdi-alert-outline</v-icon>
            การทำนายความเสียหาย
          </div>
        </div>
        <div>
          <v-simple-table>
            <template v-if="$vuetify.breakpoint.smAndUp" v-slot:default>
              <thead class="primary">
                <tr>
                  <th rowspan="2" class="text-left white--text">Scenario</th>
                  <th rowspan="2" class="text-center white--text">
                    Condition Rating
                  </th>
                  <th rowspan="2" class="text-left white--text">ความเสียหาย</th>
                  <th colspan="6" class="text-center white--text">
                    การทำนาย Condition Rating
                  </th>
                </tr>
                <tr>
                  <th class="text-center white--text">ภายใน (ปี)</th>
                  <th class="text-center white--text">จะลดลงเหลือ</th>
                  <th class="text-left white--text">ความเสียหาย</th>
                  <th class="text-center white--text">
                    ค่า S.R. <br />จะลดลงอีก
                  </th>
                  <th class="text-center white--text">
                    ค่า S.R. <br />
                    คงเหลือ
                  </th>
                  <th class="text-left white--text">การดำเนินการ</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(prediction, predictionKey) in predictTable.table"
                  :key="predictionKey"
                  :class="
                    prediction.id == predictTable.value.id &&
                    'primary--text font-weight-bold forcast-background'
                  "
                >
                  <td class="text-center">{{ prediction.id }}</td>
                  <td class="text-center">
                    {{ prediction.compare_operator }}
                    {{ prediction.condition_value }}
                  </td>
                  <td>{{ prediction.condition_text }}</td>
                  <td v-if="prediction.id != 5" class="text-center">
                    {{ prediction.in_year }}
                  </td>
                  <td v-if="prediction.id != 5" class="text-center">
                    {{ prediction.condition_predict_value }}
                  </td>
                  <td v-if="prediction.id != 5">
                    {{ prediction.condition_predict_text }}
                  </td>
                  <td v-if="prediction.id != 5" class="text-center">
                    {{ prediction.reduce_percent }}%
                  </td>
                  <td
                    v-if="
                      prediction.id != 5 &&
                      prediction.id == predictTable.value.id
                    "
                    class="text-center"
                  >
                    {{ parseFloat(predictTable.value.sr).toFixed(2) }}
                  </td>
                  <td v-else-if="prediction.id != 5"></td>
                  <td :colspan="prediction.id == 5 ? 6 : 1">
                    {{ prediction.description }}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="9" class="primary--text">
                    * ใช้คะแนน condition rating
                    ค่าที่ต่ำที่สุดของโครงสร้างส่วนบนหรือโครงสร้างส่วนล่าง
                  </td>
                </tr>
              </tfoot>
            </template>
            <template v-else v-slot:default>
              <tbody
                v-for="(prediction, index) in predictTable.table"
                :key="index"
              >
                <tr
                  class="mt-4"
                  :class="
                    prediction.id == predictTable.value.id &&
                    'primary--text font-weight-bold forcast-background'
                  "
                >
                  <td class="pa-2">
                    <table style="width: 100%">
                      <tbody>
                        <tr>
                          <th class="text-left py-2">Scenario</th>
                          <td class="text-right">{{ prediction.id }}</td>
                        </tr>
                        <tr>
                          <th class="text-left py-2">Condition Rating</th>
                          <td class="text-right">
                            {{ prediction.compare_operator }}
                            {{ prediction.condition_value }}
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left py-2">ความเสียหาย</th>
                          <td class="text-right">
                            {{ prediction.condition_text }}
                          </td>
                        </tr>
                        <tr v-if="prediction.id != 5">
                          <th class="text-left py-2">ภายใน (ปี)</th>
                          <td class="text-right">{{ prediction.in_year }}</td>
                        </tr>
                        <tr v-if="prediction.id != 5">
                          <th class="text-left py-2">
                            Condition Rating <br />
                            จะลดลงเหลือ
                          </th>
                          <td class="text-right">
                            {{ prediction.condition_predict_value }}
                          </td>
                        </tr>
                        <tr v-if="prediction.id != 5">
                          <th class="text-left py-2">ความเสียหาย</th>
                          <td class="text-right">
                            {{ prediction.condition_predict_text }}
                          </td>
                        </tr>
                        <tr v-if="prediction.id != 5">
                          <th class="text-left py-2">ค่า S.R. จะลดลงอีก</th>
                          <td class="text-right">
                            {{ prediction.reduce_percent }}%
                          </td>
                        </tr>
                        <tr v-if="prediction.id != 5">
                          <th class="text-left py-2">ค่า S.R. คงเหลือ</th>
                          <td
                            v-if="prediction.id == predictTable.value.id"
                            class="text-right py-2"
                          >
                            {{ parseFloat(predictTable.value.sr).toFixed(2) }}
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left my-4">การดำเนินการ</th>
                          <td class="text-right">
                            {{ prediction.description }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <v-divider></v-divider>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data: () => ({
    headers: [
      {
        text: 'scenario',
        align: 'center',
        sortable: false,
        value: 'id',
        class: 'primary rounded-tl-lg white--text'
      },
      {
        text: 'condition',
        align: 'center',
        sortable: false,
        value: 'compare_operator',
        class: 'primary white--text'
      },
      {
        text: 'ความเสียหาย',
        align: 'center',
        sortable: false,
        value: 'condition_text',
        class: 'primary white--text'
      },
      {
        text: 'ภายใน (ปี)',
        align: 'center',
        sortable: false,
        value: 'in_year',
        class: 'primary white--text'
      },
      {
        text: 'จะลดลงเหลือ',
        align: 'center',
        sortable: false,
        value: 'condition_predict_value',
        class: 'primary white--text'
      },
      {
        text: 'ความเสียหาย',
        align: 'center',
        sortable: false,
        value: 'condition_predict_text',
        class: 'primary white--text'
      },
      {
        text: ' ค่า S.R. จะลดลงอีก',
        align: 'center',
        sortable: false,
        value: 'reduce_percent',
        class: 'primary white--text'
      },
      {
        text: 'ค่า S.R. คงเหลือ',
        align: 'center',
        sortable: false,
        value: 'condition_text',
        class: 'primary white--text'
      },
      {
        text: 'การดำเนินการ',
        align: 'center',
        sortable: false,
        value: 'description',
        class: 'primary white--text'
      }
    ],
    items: [],
    forecastTable: {
      1: [
        'มากกว่าเท่ากับ 6',
        'ยังไม่พบความเสียหาย',
        '11',
        '5',
        'จะพบรอยแตกเล็กน้อย',
        '10%',
        '90',
        'ดำเนินการตรวจสอบปกติเพื่อติดตามความเสียหายเบื้องต้นของสะพาน'
      ],
      2: [
        '5',
        'รอยแตกเล็กน้อย',
        '9',
        '4',
        'มีรอยแตกชัดเจน',
        '15%',
        '75',
        'ดำเนินการตรวจสอบหลักเพื่อติดตามความเสียหายของสะพาน และทำการซ่อมบำรุงเบื้องต้นตามความเสียหายที่พบ'
      ],
      3: [
        '4',
        'รอยแตกชัดเจน',
        '8',
        '3',
        'เกิดการหลุดล่อนของคอนกรีต',
        '15%',
        '60',
        'ต้องได้รับการตรวจสอบชนิดพิเศษและซ่อมบำรุงสะพานเพื่อป้องกันอันตรายที่จะเกิดขึ้นกับผู้ใช้สะพาน'
      ],
      4: [
        '3',
        'การหลุดล่อนของคอนกรีต',
        '34',
        '2',
        'โครงสร้างวิบัติ',
        '15%',
        '45',
        'ต้องได้รับการตรวจสอบฉุกเฉินและซ่อมบำรุงสะพานเพื่อป้องกันอันตรายที่จะเกิดขึ้นกับผู้ใช้สะพาน'
      ],
      5: [
        'น้อยกว่าเท่ากับ 2',
        'โครงสร้างวิบัติ',
        'ต้องได้รับการตรวจสอบฉุกเฉินและซ่อมบำรุงสะพานทันทีเพื่อป้องกันอันตรายที่จะเกิดขึ้นกับผู้ใช้สะพาน'
      ]
    },
    predictTable: []
  }),
  mounted () {
    this.getPredictionDamage()
  },
  methods: {
    async getPredictionDamage () {
      this.predictTable = await this.$store.dispatch('getPredictionDamage', {
        bridgeId: this.$route.query.id
      })
      console.log(this.predictTable)
    }
  }
}
</script>

<style scoped>
.primary-border {
  border: 1px solid #c22d47;
}
.background-grey {
  background-color: #f9f9f9;
}
.detail {
  font-family: 'Sarabun';
}
th {
  border: 0.5px solid rgba(255, 255, 255, 0.5);
  white-space: nowrap;
}
.forcast-background {
  background-color: #fff9cc;
}
.forcast-background:hover {
  background-color: #fff9cc !important;
}
</style>
